import React, { useState } from "react"
import Modal from "react-modal"
import { AiOutlineClose } from "react-icons/ai"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { GPT3Demo } from "../../state/actions/gpt3"
import LottieIcon from "../lottieIcon"
import loadingIcon from "../../images/icons/clock.json"
import Editor from "../services/editor"
import services from "../../data/services"
import { Link } from "gatsby"
import { TypeAnimation } from "react-type-animation"

// This is for React Select
const options = services.slice(0, 10).map(service => ({
  value: service.name,
  label: (
    <div className="flex items-center">
      <img src={service.image} className="h-4 w-4 mr-2" alt={service.name} />
      <p className="text-l">{service.name}</p>
    </div>
  ),
}))

// Add another option to the select dropdown
options.push({
  value: "custom",
  label: (
    <div className="flex items-center">
      <p className="text-l">+ Much More</p>
    </div>
  ),
})

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxHeight: "80%",
    maxWidth: "660px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
}

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('yourAppElement')

function DemoModal({ demo, darkMode }) {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [promptInput, setPromptInput] = useState("")
  const [response, setResponse] = useState("")

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    // clearState
    setPromptInput("")
    setResponse("")
    setIsOpen(false)
  }

  const handleGenerate = async () => {
    if (!promptInput) {
      toast.error("Please enter a topic")
      return
    }

    openModal()
    const response = await demo(promptInput)
    setResponse(response)
  }

  let sequence = []
  services.forEach(service => {
    sequence.push(service.name)
    sequence.push(1000)
  })

  return (
    <div>
      <div className="md:flex items-center justify-between text-center md:text-left">
        <div className={`${!darkMode ? "text-gray-800" : "text-white"}`}>
          <h1
            className={`${
              !darkMode ? " " : " "
            } text-3xl xl:text-5xl font-bold`}
          >
            Try Liyana AI
          </h1>
          <div className="md:flex mt-1 md:mt-3 mb-3">
            <p className="md:mr-2">What do you want Liyana to write? </p>
            <TypeAnimation
              sequence={sequence}
              deletionSpeed={0}
              delay={1000}
              style={{ fontWeight: "bold" }}
              //  Replacing previous Text
              wrapper="span"
              repeat={Infinity}
            />
          </div>
        </div>
        {/* 
        <div className='mb-4 lg:mb-0 hidden md:block text-black  w-64 '>
          <button>View All Services</button>
          <Select
            options={options}
            placeholder={options[0].label}
            isSearchable={true}
            onChange={option => {
              if (option.value !== 'Blog Article') {
                navigate('/app/signup', {
                  state: { service: true }
                })
              }
            }}
          />
        </div> */}
      </div>

      <input
        type="text"
        className="
              text-gray-800
                px-4 py-2 w-full rounded border-2 border-gray-200
            "
        placeholder="Example: Write a short story about a dog"
        value={promptInput}
        onChange={e => setPromptInput(e.target.value)}
      />

      <button
        className="bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] text-white font-bold py-2 px-20 rounded my-4 w-full md:w-auto disabled:opacity-60"
        onClick={handleGenerate}
        disabled={promptInput === ""}
      >
        Write For Me
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        overlayClassName="overlayClassName z-10"
        onAfterClose={closeModal}
      >
        {/* Close Button */}
        <div className="absolute top-0 right-0 p-4 z-20">
          <button
            className="text-gray-400 hover:text-green-500 duration-100 text-2xl"
            onClick={closeModal}
          >
            <AiOutlineClose />
          </button>
        </div>

        <div className="items-start text-gray-800 mt-5 pb-5 pt-10">
          <h1 className="text-xl font-bold capitalize mb-4  text-center ">
            {promptInput}
          </h1>
          {response ? (
            <>
              <div className="max-h-[400px] overflow-scroll mb-5">
                <Editor
                  openResponse={response
                    .trim()
                    .concat(
                      "...",
                      `\n\n---> This is just a demo  <---\nClick "Start For Free" to keep reading, and access all of Liyana's features!`
                    )}
                />
              </div>
              {/* Fade out the text above */}
              {/* <span className='absolute bottom-0 left-0 right-0 h-60 bg-gradient-to-t from-white' /> */}
            </>
          ) : (
            <div className="m-auto my-10">
              <LottieIcon icon={loadingIcon} className="m-auto " />
            </div>
          )}
        </div>

        <div className="text-center">
          <Link to="/app/signup">
            <button className="w-100 mx-auto bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] text-white font-bold py-2 px-5 rounded ">
              Start For Free
            </button>
            {/* Write an enticing end note */}
            <p className="text-xs mt-2  text-gray-800">
              Get 2,500 Words Free! No Credit Card Required.
            </p>
          </Link>
        </div>
      </Modal>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    darkMode: state.utils.darkMode,
  }
}

const mapDispatchToProps = dispatch => ({
  demo: text => dispatch(GPT3Demo(text)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DemoModal)
