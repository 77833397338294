import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { connect } from "react-redux"

const Cta = ({ darkMode }) => {
  return (
    <div className="container mx-auto relative px-5">
      {/* Background Image */}
      <div
        className="absolute w-full h-full md:w-4/6  z-0 "
        style={{ left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}
      >
        <StaticImage
          src="../../images/landing/dots.png"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
          className="w-full h-full floatAnimation"
        />
      </div>

      <div className="container mx-auto w-full relative"></div>
      <div className="flex flex-col  md:flex-row items-center justify-center gap-10 md:gap-20 my-20 relative z-10">
        <div
          className={`${
            darkMode ? "text-white" : "text-black"
          } text-center md:text-start`}
        >
          <h1 className="text-xl md:text-5xl uppercase font-bold mb-2 text-center md:text-start">
            Liyana <br /> The Writing Assistant
          </h1>
          <p className="text-center md:text-start">
            Liyana can help you create beautiful, error-free documents faster!
          </p>

          <Link to="/app/signup">
            <button
              className=" cursor-pointer mt-4 
                bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] 
                px-8 py-3 rounded-xl text-white uppercase font-bold self-center
            "
            >
              Start for Free
            </button>
          </Link>
        </div>

        <div className="">
          <StaticImage
            src="../../images/cta.png"
            alt=""
            placeholder="blurred"
            className="w-60 md:w-96 mx-auto z-10"
            layout="fullWidth"
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    darkMode: state.utils.darkMode,
  }
}

export default connect(mapStateToProps)(Cta)
