import React, { useState } from 'react'
import { FaChessQueen, FaCheck, FaCrown } from 'react-icons/fa'
import plans from '../../data/pricing'
import { Link } from 'gatsby'
import { connect } from 'react-redux'

const Pricing = ({ darkMode }) => {
  // Set Monthly or Annual Pricing
  const [pricing, setPricing] = useState('monthly')

  return (
    <div className=' relative z-10 px-5'>
      <h1
        className={` ${
          darkMode ? 'text-white' : 'text-black'
        } uppercase text-3xl md:text-5xl font-bold text-center mb-4`}
      >
        Pricing
      </h1>
      <p className={` ${darkMode ? 'text-white' : 'text-black'}  text-center`}>
        Our pricing plans are designed to offer individuals & businesses the
        flexibility to choose the plan that best fits their needs!
      </p>
      {/* Select Monthly or Annual Pricing */}
      <div className='flex justify-center items-center mt-5 gap-5'>
        {darkMode ? (
          <>
            <button
              className={`uppercase rounded
          py-2 px-10 text-white font-bold
          ${
            pricing === 'monthly' &&
            `bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C]`
          }
          `}
              onClick={() => setPricing('monthly')}
            >
              Monthly
            </button>{' '}
            <button
              className={` text-white uppercase rounded py-2 px-10 font-bold
          ${
            pricing === 'annual' &&
            `bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C]`
          }
        `}
              onClick={() => setPricing('annual')}
            >
              Annual
            </button>{' '}
          </>
        ) : (
          <>
            <button
              className={`uppercase rounded
          py-2 px-10 font-bold
          ${
            pricing === 'monthly' &&
            `bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] text-white`
          }
          `}
              onClick={() => setPricing('monthly')}
            >
              Monthly
            </button>{' '}
            <button
              className={`uppercase rounded
          py-2 px-10 font-bold
          ${
            pricing === 'annual' &&
            `bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] text-white`
          }
        `}
              onClick={() => setPricing('annual')}
            >
              Annual
            </button>{' '}
          </>
        )}
      </div>

      {/* Cards */}
      <div className='flex flex-col md:flex-row justify-center items-center mt-10 gap-10 '>
        {plans.map((plan, idx) => (
          <div
            key={idx}
            className='drop-shadow relative min-w-[300px] min-w-[300px] min-h-[450px] text-white  '
          >
            {/* Background with transparent */}
            <div
              className={`absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#CA499A] to-[#BE3ED2] rounded-lg shadow-lg z-0`}
            ></div>

            <div className='relative z-10'>
              <div className='px-6 pt-8 relative'>
                <div className='flex justify-between items-center'>
                  <h1 className='self-start text-xl font-bold'>{plan.title}</h1>
                  {/* Crown */}
                  {plan.title === 'Pro' && <FaCrown color='#DCFF04' />}
                  {plan.title === 'Starter' && <FaChessQueen color='#DCFF04' />}
                </div>
                <p className='text-4xl font-bold  my-8  text-center'>
                  {pricing === 'monthly'
                    ? `$${plan.monthly} `
                    : `$${plan.annual} `}
                  <span className='text-sm'>
                    {' '}
                    {pricing === 'monthly' ? `/month` : `/year`}
                  </span>
                </p>

                <Link to='/app/signup'>
                  <button className='drop-shadow-lg  bg-white text-black text-xs uppercase mx-auto font-bold py-2  rounded-lg mx-auto w-full mb-6'>
                    Sign Up
                  </button>
                </Link>
              </div>

              {/* Line Separator */}
              <div className='w-full h-0.5 bg-white my-1 '></div>

              <div className='px-6 py-6 mb-10'>
                <ul className=''>
                  {plan.features.map((feature, idx) => (
                    <li
                      key={idx}
                      className='flex items-center gap-4 text-sm mb-3'
                    >
                      {/* Check Icon */}
                      <span className='text-xs'>
                        <FaCheck />
                      </span>
                      <p>{feature}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Add stylistic white border at the bottom */}
            <div className='absolute bottom-0 left-0 w-full h-2 bg-white rounded-b'></div>
          </div>
        ))}
      </div>
      {/* Line separator with text in center */}
      <div className='flex items-center container mx-auto mt-10 lg:mt-20'>
        <div
          className={`hidden md:block w-1/3 h-0.5 ${
            darkMode ? 'bg-white' : 'bg-pink-200'
          }`}
        ></div>
        <p
          className={`${
            darkMode ? 'text-white' : 'text-black'
          } w-full text-center md:w-1/3 border rounded-lg mx-10 p-2 font-bold`}
        >
          Need a Custom Enterprise Plan?{' '}
          <a href='mailto:info@liyana.ai'>
            <span className=' ml-1 underline cursor-pointer text-pink-600'>
              Contact us
            </span>
          </a>
        </p>{' '}
        <div
          className={`hidden md:block w-1/3 h-0.5 ${
            darkMode ? 'bg-white' : 'bg-pink-200'
          }`}
        ></div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    darkMode: state.utils.darkMode
  }
}

export default connect(mapStateToProps)(Pricing)
