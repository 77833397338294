import React, { useEffect, useState } from 'react'
import { BsSearch } from 'react-icons/bs'
import { Link } from 'gatsby'
import services, { categories } from '../../data/services'
import { useStaticQuery, graphql } from 'gatsby'
import { connect } from 'react-redux'

const Services = ({ darkMode }) => {
  const [search, setSearch] = useState('')
  const [category, setCategory] = useState('all')
  const [show, setShow] = useState(6)

  useEffect(() => {
    const windowWidth = window.innerWidth
    if (windowWidth > 768) {
      setShow(12)
    } else if (windowWidth > 425) {
      setShow(4)
    }
  }, [])

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  const handleCategory = e => {
    setSearch('')
    setCategory(e.target.value.toLowerCase())
  }

  const filteredServices = services.filter(service => {
    if (search) {
      return service.name.toLowerCase().includes(search.toLowerCase())
    } else if (category === 'all') {
      return service
    } else {
      return service.category === category
    }
  })

  return (
    <div
      className={`py-20 mx-auto min-h-screen 
      ${
        darkMode &&
        `bg-gradient-to-b from-transparent via-[#000000] to-transparent`
      } px-5`}
    >
      <h2 className={`text-xl md:text-5xl uppercase  text-center mb-4 font-bold ${darkMode ? " text-white" : "text-gray-800"}`}>
        Liyana Can Help You With..
      </h2>

      <div className='mx-auto text-center w-full  md:w-96 relative'>
        <input
          type='text'
          className='border py-2 px-5  w-full md:w-96 rounded-full mt-3 h-10 text-sm drop-shadow'
          placeholder='Search for something...'
          value={search}
          onChange={handleSearch}
        />
        {/* Search Button */}
        <button
          className='bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C]
          text-white rounded-full mt-3  absolute right--10 h-10 w-10 absolute right-0'
        >
          <BsSearch className='mx-auto text-lg' />
        </button>
      </div>
      {/* Add tabs to change categories */}

      <div className='flex  items-center mt-4 relative max-w-fit mx-auto overflow-x-scroll lg:overflow-x-hidden'>
        {categories.map((cat, index) => (
          <>
            {darkMode ? (
              <button
                key={index}
                className={` ${
                  cat === category &&
                  'bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] text-white  py-2 rounded'
                } 
                font-bold flex flex-col items-center justify-center px-8 py-1 m-2 rounded-lg shadow-sm uppercase duration-50 text-sm text-white `}
                onClick={handleCategory}
                value={cat}
              >
                {cat}
              </button>
            ) : (
              <button
                key={index}
                className={` ${
                  cat === category &&
                  'bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] text-white  py-2 rounded'
                } 
                font-bold flex flex-col items-center justify-center px-8 py-1 m-2 rounded-lg shadow-sm uppercase duration-50 text-sm `}
                onClick={handleCategory}
                value={cat}
              >
                {cat}
              </button>
            )}
          </>
        ))}
      </div>

      <div className='container mx-auto flex flex-wrap mt-5 md:mt-10 justify-center md:justify-start relative z-10'>
        {filteredServices.slice(0, show).map(service => {
          return (
            <div
              key={service.name}
              className='flex flex-col items-center justify-center w-full md:w-1/2 xl:w-1/3  my-2 lg:my-5 px-2 '
            >
              {/* Card with icon on the left */}
              <Link
                to='/app/signup'
                className={`flex items-center text-white border-2 border-gray-600 shadow-md hover:shadow-lg rounded-lg p-3 md:p-5 w-full cursor-pointer hover:bg-gradient-to-r hover:from-[#BC3CD8] hover:to-[#C54B8C] hover:border-transparent ${
                  !darkMode && 'text-black hover:text-white'
                }`}
              >
                <div className='flex items-center  mx-5'>
                  {/* Icon Image */}
                  <img
                    src={service.image}
                    className='w-5 h-5  md:w-8 md:h-8 '
                    alt={service.name}
                  />
                </div>
                <div>
                  <h1 className='md:text-lg font-bold'>{service.name}</h1>
                </div>
              </Link>
            </div>
          )
        })}
        {show < filteredServices.length && (
          <button
            className='bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] text-white font-bold py-2 px-10 rounded mt-10 mx-auto'
            onClick={() => setShow(show + 12)}
          >
            Show More
          </button>
        )}

        {/* Absolute positioned purple circle */}
        <div
          className={`${!darkMode && 'hidden'} absolute w-2/6 rounded-full  z-0 
          bg-circle opacity-10 blur-2xl aspect-square
          top-0 -right-60  `}
          style={{ zIndex: -1 }}
        ></div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    darkMode: state.utils.darkMode
  }
}

export default connect(mapStateToProps)(Services)
