import React from 'react'
import Landing from '../components/landing/index'
import { ToastContainer } from 'react-toastify'

export default function Index () {
  return (
    <div>
      <ToastContainer position='top-center' />
      <Landing />
    </div>
  )
}
