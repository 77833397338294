import React from 'react'
import Hero from './hero'
import Services from './services'
import Header from './header'
import Pricing from './pricing'
import Testimonials from './testimonials'
import Cta from './cta'
import Footer from './footer'
import SEO from '../seo'
import { connect } from 'react-redux'

const Index = ({ darkMode }) => {
  return (
    <div
      className={`${
        darkMode
          ? 'bg-gradient-to-b from-[#060525] via-[#02096E] via-[#030756]  via-[#130412]  via-[#050534] via-[#06041F] to-[#060317] '
          : 'bg-gradient-to-b from-[#FFFFFF] via-[#ECE9E6] to-white'
      } overflow-hidden`}
    >
      <SEO
        title='Liyana - AI Writing Assistant'
        description='
        Liyana is an AI writing assistant that helps you write better, faster, and more efficiently! Start your free trial today!
        '
        article={false}
        pathname={`/`}
      />
      <Header />
      <Hero />
      <Services />
      <Pricing />
      <Testimonials />
      <Cta />
      <Footer />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    darkMode: state.utils.darkMode
  }
}

export default connect(mapStateToProps)(Index)
