import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { FaStar } from 'react-icons/fa'
import Carousel from 'react-multi-carousel'
import { connect } from 'react-redux'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

const testimonialData = [
  {
    name: 'Nirash Goonewardena',
    title: 'Entrepreneur & Engineer',
    text: "Liyana AI has been a game-changer for my business. Before I started using Liyana AI, I was struggling to write website content and email marketing campaigns that really resonated with my customers. But now, with Liyana AI's help, I'm able to create content that's engaging, accurate, and on-brand. Liyana AI has saved me time and money, and I highly recommend it!"
  },
  {
    name: 'Tracy Cao',
    title: 'Company Director',
    text: "Liyana AI was a great asset when I was writing a business plan for my company. It helped me organize my thoughts, find the right words, and create a polished, professional document. I'm so glad I found Liyana AI, because I know my business plan wouldn't have been nearly as successful without it!"
  },
  {
    name: 'Rakith Nanayakkara',
    title: 'Property Manager',
    text: "As a business owner, I was having trouble creating ad content that was both eye-catching and on-message. Liyana AI was immensely helpful in this process. It helped me create ads that were consistent with my brand and that really resonated with my target audience. I'm so grateful for Liyana AI and the role it played in the success of my business!"
  }
]

const Testimonials = ({ darkMode }) => {
  return (
    <div className='relative px-5'>
      {/* Absolute positioned purple circle */}
      <div
        className={`absolute w-2/6 rounded-full  z-0 
        bg-circle opacity-10 blur-2xl aspect-square
        top-0 -left-40 ${darkMode ? '' : 'hidden'}`}
      ></div>

      {/* Background Image - Vector Object*/}
      <div
        className={`absolute w-full bottom-0 z-0 ${!darkMode && 'opacity-20'}`}
      >
        <StaticImage
          src='../../images/landing/object.png'
          alt=''
          placeholder='blurred'
          layout='fullWidth'
          className='floatAnimation2'
        />
      </div>

      <div className='container mx-auto py-20 relative z-10'>
        <div className='relative z-2'>
          <h1
            className={` ${
              darkMode ? 'text-white' : 'text-black'
            } uppercase text-xl md:text-5xl font-bold text-center mb-10`}
          >
            Liyana's Glowing Reviews
          </h1>

          {/* Cards */}
          <Carousel arrows={false} autoPlay infinite responsive={responsive}>
            {testimonialData.map((testimonial, index) => (
              <div
                key={index}
                className={`${
                  darkMode ? 'glass' : 'bg-pink-100'
                } flex flex-col justify-center items-center gap-5 relative mx-5 h-full`}
              >
                <div
                  className={`${
                    darkMode ? 'text-white' : 'text-black'
                  } relative z-1 p-10`}
                >
                  {/* 5 Stars */}
                  <div className='flex items-center gap-2 mb-8 text-yellow-400'>
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>{' '}
                  <p>{testimonial.text}</p>
                  <div className='flex mt-4 items-center'>
                    {/* Placeholder Image */}
                    <div>
                      <p className='font-bold'>{testimonial.name}</p>
                      <p className={`text-red-500 ${!darkMode && 'font-bold'}`}>
                        {testimonial.title}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    darkMode: state.utils.darkMode
  }
}

export default connect(mapStateToProps)(Testimonials)
