import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import DemoModal from "./demoModal"
import Video from "./video"
import { connect } from "react-redux"

const Hero = ({ darkMode }) => {
  const navbarHeight = "150px"

  return (
    <div className="text-white relative ">
      {/* Absolute positioned purple circle */}

      <div
        className={`absolute w-2/6 rounded-full  z-0 
        bg-circle opacity-10 blur-2xl aspect-square
        top-0 -left-60 ${darkMode ? "" : "hidden"}`}
      ></div>

      {/* Background Image - Vector Object*/}
      <div
        className="absolute w-full"
        style={{ top: "60%", transform: "translateY(-50%)" }}
      >
        <StaticImage
          src="../../images/landing/object.png"
          alt=""
          placeholder="blurred"
          layout="fullWidth"
          className={`floatAnimation2  w-full ${!darkMode && "opacity-20"}`}
        />
      </div>

      <div
        className="container  mx-auto px-5 py-10 z-2 relative flex  items-center  justify-center z-10"
        style={{ minHeight: `calc(100vh - ${navbarHeight} )` }}
      >
        <div className="flex items-center h-32 justify-center h-max w-full">
          <div className="w-full relative z-10 ">
            <div className="w-3/5 mx-auto  block md:hidden mb-10 relative">
              <StaticImage
                src="../../images/landing/hero.png"
                alt="Liyana AI"
                placeholder="blurred"
                className="w-full m-auto z-10 floatAnimation "
                layout="fullWidth"
              />
            </div>
            <div className="w-full mx-auto block md:hidden mb-10 absolute  mx-auto  z-0 top-0">
              <StaticImage
                src="../../images/landing/hero-bg.png"
                alt="Liyana AI"
                placeholder="blurred"
                style={{ minWidth: "140%", left: "-20%" }}
                layout="fullWidth"
              />
            </div>

            <DemoModal />
            <p
              className={` ${
                darkMode ? "text-white" : "text-black"
              } text-sm text-center md:text-left`}
            >
              With Liyana's intuitive interface and selection of pre-made
              templates, Liyana has made it simple to create engaging and
              impactful content in minutes.
            </p>
          </div>

          <div className="w-full relative hidden lg:block">
            <div className="relative">
              <StaticImage
                src="../../images/landing/hero.png"
                alt="Liyana AI"
                placeholder="blurred"
                className="w-96 m-auto z-10 floatAnimation"
                layout="fullWidth"
              />
            </div>

            <div className="absolute w-full mx-auto  z-0 top-0">
              <StaticImage
                src="../../images/landing/hero-bg.png"
                alt="Liyana AI"
                placeholder="blurred"
                style={{ minWidth: "140%", left: "-20%" }}
                layout="fullWidth"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative z-1">
        <Video darkMode={darkMode} />
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    darkMode: state.utils.darkMode,
  }
}

export default connect(mapStateToProps)(Hero)
