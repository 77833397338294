import React from 'react'
import ReactPlayer from 'react-player/lazy'

export default function Video ({ darkMode }) {
  return (
    <div className='container mx-auto'>
      {/* Absolute positioned purple circle */}

      <div
        className={`absolute w-2/6 rounded-full  z-0 
        bg-circle opacity-10 blur-2xl aspect-square
        md:-left-40 ${darkMode ? '' : 'hidden'}`}
      ></div>

      <div className='flex items-center mx-auto flex-col py-10 px-5 max-w-4xl aspect-video relative z-10'>
        <ReactPlayer
          url='https://youtu.be/6jU7dkm9ipA'
          width='100%'
          height='100%'
        />
        <p
          className={` ${
            darkMode ? 'text-white' : ' text-black'
          }  text-sm  mt-6 text-center font-bold italic`}
        >
          Liyana allows you to create content faster than ever!
        </p>
        <button className='mt-3 bg-gradient-to-r from-[#BC3CD8] to-[#C54B8C] text-white font-bold py-2 px-10 rounded '>
          Start Free Trial
        </button>
      </div>
    </div>
  )
}
